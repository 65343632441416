import Header from "../../Components/Header";
import { use, useState, useEffect } from "react";
import Form from "./Form";
import Headline from "./Headline";
import Swal from "sweetalert2";
import { Suspense, React, Fragment } from "react";
import ReactLoading from "react-loading";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { sendPostRequest } from "../../Client";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { Alert } from "flowbite-react";

export default function Home() {
  const [strategies, setStrategies] = useState(["Strategy 1"]);

  const [companyID, setCompanyID] = useState(1);
  const [currency, setCurrency] = useState(1);

  const [calculation, setCalculation] = useState("");
  const [companyFee, setCompanyFee] = useState("");

  const [open, setOpen] = useState(false);

  function numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function getCurrencySymbol(currency) {
    if (currency == 1) {
      return "£";
    } else if (currency == 2) {
      return "$";
    } else if (currency == 3) {
      return "€";
    } else if (currency == 4) {
      return "CZK";
    } else {
      return "£";
    }
  }

  function addStrategy() {
    const number = strategies.length + 1;
    setStrategies([...strategies, "Strategy " + number]);
  }

  const queryClient = useQueryClient();

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Record added");
  };

  const initialDate = new Date().toISOString().split("T")[0];
  const initialDateArray = initialDate.split("-");

  const [inputs, setInputs] = useState({
    second_advisor_split_from_advisor: "25",
    date: "2024-10-08",
    ultimate_owner: "7",
    start_date: "2024-10-08",
    product: "WRAP",
    product_provider: "AJ Bell",
    forename: "Joff",
    surname: "Black",
    client_ref: "ref12345",
    bond_platform_no: "bond platform no",
    initial_total_value: "500000",
    advisor: "3",
    agency: "AI",
    introducer: "4",
    introducer_split_from_advisor: "10",
    applied_to: "Whole Fee",
    second_advisor: "3",
  });

  const [formInputs, setFormInputs] = useState({
    "Strategy 1--policy_number": "111111",
    "Strategy 1--pension_investment": "Pension",
    "Strategy 1--product_type": "Insured Pension",
    "Strategy 1--trustee": "mr A",
    "Strategy 1--trustee_no": "trustee no",
    "Strategy 1--sub_policy_number_1": "1",
    "Strategy 1--sub_policy_number_2": "2",
    "Strategy 1--sub_policy_number_3": "3",
    "Strategy 1--sub_policy_number_4": "4",
    "Strategy 1--dfm_servicing_company": "Yes",
    "Strategy 1--team_leader": "5",
    "Strategy 1--provider": "1",
    "Strategy 1--invested_value": "500000",
    "Strategy 1--portfolio": "2",
    "Strategy 1--strategy_type": "59",
    "Strategy 1--fees_paid_from": "Servicing Company",
    "Strategy 1--strategy_fee": "0.4",
    "Strategy 1--advisor_charge": "10",
    "Strategy 1--ongoing_charge": "1",
    "Strategy 1--ongoing_fixed_charge": "5",
    "Strategy 1--unique_identifier": "5",
  });

  // record add
  const recordAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    setCalculation(res.calculation);
    setCompanyFee(res.companyFee);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return recordAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
    },
  });

  // confirm delete
  const areYouSure = (value) => {
    Swal.fire({
      title: "Are you sure you want to delete " + value + "?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#de3333",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStrategy(value);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deleteStrategy = (value) => {
    setStrategies((strategies) => {
      return strategies.filter((strategy) => strategy !== value);
    });
  };

  function BigSpinner() {
    return <h2>🌀 Loading...</h2>;
  }

  return (
    <>
      <Header />

      <Suspense fallback={<ReactLoading type={"spin"} color="green" />}>
        <section class="bg-gray-100 dark:bg-gray-900 py-3 sm:py-5">
          <div class="px-4 mx-auto max-w-screen-2xl lg:px-12 mb-12">
            <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
              <div className="p-8">
                <form>
                  <Headline
                    companyID={companyID}
                    setCompanyID={setCompanyID}
                    inputs={inputs}
                    setInputs={setInputs}
                    setCurrency={setCurrency}
                    currency={currency}
                  />

                  {strategies.map((item, key) => (
                    <div key={key} className="py-4">
                      <Form
                        companyID={companyID}
                        item={item}
                        areYouSure={areYouSure}
                        setFormInputs={setFormInputs}
                        formInputs={formInputs}
                        inputs={inputs}
                      />
                    </div>
                  ))}

                  <div className="flex justify-end mb-4">
                    <button
                      type="button"
                      class="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                      onClick={() => {
                        addStrategy();
                      }}
                    >
                      Add another strategy +
                    </button>
                  </div>

                  <hr></hr>

                  <button
                    type="button"
                    onClick={async () => {
                      if (!companyID) {
                        alert("Please enter a Company");
                        return;
                      }

                      if (!formInputs["Strategy 1--invested_value"]) {
                        alert("Please enter a value for Invested Value");
                        return;
                      }

                      if (!formInputs["Strategy 1--strategy_fee"]) {
                        alert("Please enter a value for Strategy Fee %");
                        return;
                      }

                      if (!formInputs["Strategy 1--advisor_charge"]) {
                        alert(
                          "Please enter a value for Split / Adviser Charge"
                        );
                        return;
                      }

                      setOpen(true);

                      try {
                        const res = await doAddMutation({
                          path: "records/add",
                          body: {
                            companyID: companyID,
                            owner: inputs.ultimate_owner,
                            startDate: inputs.start_date,
                            product: inputs.product,
                            forename: inputs.forename,
                            surname: inputs.surname,
                            clientRef: inputs.client_ref,
                            bondPlatformNo: inputs.bond_platform_no,
                            initial_total_value: inputs.initial_total_value,
                            exchangeRate: inputs.exchange_rate,
                            currency: currency,
                            advisor: inputs.advisor,
                            agency: inputs.agency,
                            introducer: inputs.introducer,
                            introducerSplit:
                              inputs.introducer_split_from_advisor,
                            secondAdvisorSplit:
                              inputs.second_advisor_split_from_advisor,
                            secondAdvisor: inputs.second_advisor,
                            appliedTo: inputs.applied_to,
                            formInputs: formInputs,
                          },
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    className="mt-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-slate-600 focus:outline-none dark:focus:ring-green-600"
                  >
                    Submit
                  </button>
                </form>

                <Transition.Root show={open} as={Fragment}>
                  <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                      <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                          <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                          >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                  <div className="flex items-center justify-between">
                                    <Dialog.Title className="text-lg font-medium text-gray-900"></Dialog.Title>
                                    <div className="ml-3 flex h-7 items-start w-full">
                                      <div className="flex flex-col w-full mt-2 mb-10">
                                        <div className="text-xl ml-2 mb-2">
                                          RecordID: {calculation?.ID}
                                        </div>

                                        {calculation[0]?.invested_value ? (
                                          <div className="w-full text-xs border-slate-200 border-2 rounded-2xl p-6 bg-slate-50">
                                            {/* <div className="text-xl">
                                              Strategy 1
                                            </div> */}
                                            <div className="mt-4 flex flex-row">
                                              <div className="w-4/5">
                                                Invested Value:{" "}
                                              </div>
                                              <div className="w-1/5">
                                                {getCurrencySymbol(
                                                  calculation[0]?.currency
                                                )}
                                                {numberWithCommas(
                                                  calculation[0]?.invested_value
                                                )}
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Adviser Declared Fee:{" "}
                                              </div>
                                              <div className="w-1/5">
                                                {calculation[0]?.ongoing_charge}
                                                %
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Strategy Fee
                                              </div>
                                              <div className="w-1/5">
                                                {(calculation[0]?.strategy_fee)*100}%
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Total Client Fee paid from
                                                Product
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {numberWithCommas(
                                                  (calculation[0]
                                                    ?.ongoing_charge *
                                                    calculation[0]
                                                      ?.invested_value) /
                                                    100
                                                )}
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Adviser Fee (Whole Fee)
                                              </div>
                                              <div className="w-1/5">
                                                {(calculation[0]
                                                  ?.ongoing_charge -
                                                  calculation[0]?.strategy_fee) * 100}
                                                %
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Company fee
                                              </div>
                                              <div className="w-1/5">
                                                {companyFee}%
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Introducer paid from Whole Fee
                                                (IFS)
                                              </div>
                                              <div className="w-1/5">0%</div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Introducer paid from AFs (IAFs)
                                              </div>
                                              <div className="w-1/5">
                                                {
                                                  inputs.introducer_split_from_advisor
                                                }
                                                %
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                2nd adviser
                                              </div>
                                              <div className="w-1/5">
                                                {
                                                  inputs.second_advisor_split_from_advisor
                                                }
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Strategy level
                                              </div>
                                              <div className="w-1/5">10%</div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Company level
                                              </div>
                                              <div className="w-1/5">10%</div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Adviser level
                                              </div>
                                              <div className="w-1/5">10%</div>
                                            </div>

                                            <hr className="pt-2 mt-2"></hr>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Adviser Fee
                                              </div>
                                              <div className="w-1/5">
                                                {(calculation[0]
                                                  ?.ongoing_charge -
                                                  calculation[0]?.strategy_fee)*100}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                After Company Introducer &
                                                Company
                                              </div>
                                              <div className="w-1/5">
                                                {((1 - companyFee / 100) *
                                                  (calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee))*100}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                After Adviser Introducer & 2nd
                                                Adviser
                                              </div>
                                              <div className="w-1/5">
                                                {(((
                                                  (1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                  (1 - companyFee / 100) *
                                                  (calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee)
                                                ))*100).toFixed(2)}
                                                %
                                              </div>
                                            </div>

                                            <hr className="pt-2 mt-2"></hr>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team leader strategy level
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  0.1 *
                                                  calculation[0]?.strategy_fee
                                                ).toFixed(2)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team leader company level
                                              </div>
                                              <div className="w-1/5">
                                                {(0.1 * companyFee).toFixed(2)}%
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team leader advisor level
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  0.1 *
                                                  ((1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    (1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </div>
                                            </div>

                                            <hr className="pt-2 mt-2"></hr>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                STRATEGY NET FEE (after Team
                                                Leader)
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  (calculation[0]
                                                    ?.strategy_fee -
                                                    0.1 *
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                  100
                                                ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {((calculation[0]
                                                  ?.strategy_fee -
                                                  0.1 *
                                                    calculation[0]
                                                      ?.strategy_fee) *
                                                  calculation[0]
                                                    ?.invested_value) /
                                                  100}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                COMPANY NET FEE (after Team
                                                Leader)
                                              </div>
                                              <div className="w-1/5">
                                                {numberWithCommas(
                                                  (calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee) *
                                                    companyFee -
                                                    0.1 *
                                                      companyFee *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee)
                                                )}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {numberWithCommas(
                                                  (
                                                    (((calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                      companyFee -
                                                      0.1 *
                                                        companyFee *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) /
                                                      100) *
                                                    (calculation[0]
                                                      ?.invested_value /
                                                      100)
                                                  ).toFixed(2)
                                                )}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                IFS Introducer
                                              </div>
                                              <div className="w-1/5">
                                                {(calculation[0]
                                                  ?.ongoing_charge -
                                                  calculation[0]
                                                    ?.strategy_fee) *
                                                  0}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">£0</div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                IAFs (Introducer)
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  (1 - companyFee / 100) *
                                                  (calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee) *
                                                  inputs.introducer_split_from_advisor
                                                ).toFixed(1)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {(
                                                  ((1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                    (inputs.introducer_split_from_advisor /
                                                      100) *
                                                    calculation[0]
                                                      ?.invested_value) /
                                                  100
                                                ).toFixed(2)}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                2nd Advisor
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  (1 - companyFee / 100) *
                                                  (calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee) *
                                                  inputs.second_advisor_split_from_advisor
                                                ).toFixed(1)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {(
                                                  ((1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                    (inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    calculation[0]
                                                      ?.invested_value) /
                                                  100
                                                ).toFixed(2)}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team Leader (Strategy)
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  100 *
                                                  0.1 *
                                                  calculation[0]?.strategy_fee
                                                ).toFixed(2)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {(
                                                  (0.1 *
                                                    calculation[0]
                                                      ?.strategy_fee *
                                                    calculation[0]
                                                      ?.invested_value) /
                                                  100
                                                ).toFixed(2)}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team Leader (Company)
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  (100 *
                                                    0.1 *
                                                    companyFee *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) /
                                                  100
                                                ).toFixed(1)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {(
                                                  (((0.1 *
                                                    companyFee *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) /
                                                    100) *
                                                    calculation[0]
                                                      ?.invested_value) /
                                                  100
                                                ).toFixed(2)}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Team Leader (Advisor)
                                              </div>
                                              <div className="w-1/5">
                                                {(
                                                  100 *
                                                  0.1 *
                                                  ((1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    (1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) *
                                                  100 *
                                                  (((1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    (1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) /
                                                    100)
                                                ).toFixed(1)}
                                                %
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">
                                                £
                                                {(
                                                  0.1 *
                                                  ((1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    (1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) *
                                                  100 *
                                                  (((1 -
                                                    inputs.introducer_split_from_advisor /
                                                      100 -
                                                    inputs.second_advisor_split_from_advisor /
                                                      100) *
                                                    (1 - companyFee / 100) *
                                                    (calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee)) /
                                                    100) *
                                                  (calculation[0]
                                                    ?.invested_value /
                                                    100)
                                                ).toFixed(2)}
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Advisor Net Fee Payable
                                              </div>
                                              <div className="w-1/5">
                                                {
                                                  ((calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee -
                                                    ((calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                      companyFee -
                                                      0.1 *
                                                        companyFee *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) /
                                                      100 -
                                                    ((1 - companyFee / 100) *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee) *
                                                      inputs.introducer_split_from_advisor) /
                                                      100 -
                                                    ((1 - companyFee / 100) *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee) *
                                                      inputs.second_advisor_split_from_advisor) /
                                                      100 +
                                                    (100 *
                                                      0.1 *
                                                      calculation[0]
                                                        ?.strategy_fee) /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      calculation[0]
                                                        ?.strategy_fee) /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      companyFee *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee)) /
                                                      100 /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      ((1 -
                                                        inputs.introducer_split_from_advisor /
                                                          100 -
                                                        inputs.second_advisor_split_from_advisor /
                                                          100) *
                                                        (1 - companyFee / 100) *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) *
                                                      100 *
                                                      (((1 -
                                                        inputs.introducer_split_from_advisor /
                                                          100 -
                                                        inputs.second_advisor_split_from_advisor /
                                                          100) *
                                                        (1 - companyFee / 100) *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) /
                                                        100)) /
                                                      100)*100).toFixed(2)
                                                }%
                                              </div>
                                            </div>

                                            <div className="flex flex-row">
                                              <div className="w-4/5">
                                                Amount
                                              </div>
                                              <div className="w-1/5">£{((calculation[0]
                                                    ?.ongoing_charge -
                                                    calculation[0]
                                                      ?.strategy_fee -
                                                    ((calculation[0]
                                                      ?.ongoing_charge -
                                                      calculation[0]
                                                        ?.strategy_fee) *
                                                      companyFee -
                                                      0.1 *
                                                        companyFee *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) /
                                                      100 -
                                                    ((1 - companyFee / 100) *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee) *
                                                      inputs.introducer_split_from_advisor) /
                                                      100 -
                                                    ((1 - companyFee / 100) *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee) *
                                                      inputs.second_advisor_split_from_advisor) /
                                                      100 +
                                                    (100 *
                                                      0.1 *
                                                      calculation[0]
                                                        ?.strategy_fee) /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      calculation[0]
                                                        ?.strategy_fee) /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      companyFee *
                                                      (calculation[0]
                                                        ?.ongoing_charge -
                                                        calculation[0]
                                                          ?.strategy_fee)) /
                                                      100 /
                                                      100 -
                                                    (100 *
                                                      0.1 *
                                                      ((1 -
                                                        inputs.introducer_split_from_advisor /
                                                          100 -
                                                        inputs.second_advisor_split_from_advisor /
                                                          100) *
                                                        (1 - companyFee / 100) *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) *
                                                      100 *
                                                      (((1 -
                                                        inputs.introducer_split_from_advisor /
                                                          100 -
                                                        inputs.second_advisor_split_from_advisor /
                                                          100) *
                                                        (1 - companyFee / 100) *
                                                        (calculation[0]
                                                          ?.ongoing_charge -
                                                          calculation[0]
                                                            ?.strategy_fee)) /
                                                        100)) /
                                                      100) * inputs.initial_total_value / 100).toFixed(2)}</div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {calculation[1]?.invested_value
                                          ? null
                                          : // <div className="w-full text-xs border-slate-200 border-2 rounded-2xl p-6 bg-slate-50">
                                            //   <div className="text-xl">
                                            //     Strategy 2
                                            //   </div>
                                            //   <div className="mt-4 flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Invested Value:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //       {numberWithCommas(
                                            //         calculation[1]?.invested_value
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Currency:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Exchange Rate:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.exchange_rate}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       AIT Portfolio:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.portfolio}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Investment Fee %:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.strategy_fee}%
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Fees Paid From:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.fees_paid_from}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Advisor Charge:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.advisor_charge}
                                            //       %
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       AISA Charge on Advisor:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {calculation[1]?.ongoing_charge}
                                            //       %
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Total Fee:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {/* AISA Total + Adviser Total  */}
                                            //       {calculation[1]?.currency == 1
                                            //         ? "£"
                                            //         : "$"}
                                            //       {numberWithCommas(
                                            //         calculation[1]?.Total_Fee
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       AISA Total:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {/* (Investment Fee %) * (Invested Value) + (advisor % + advisor fix) * (aisa charge on advisor) */}
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //       {numberWithCommas(
                                            //         calculation[1]?.AISA_Total
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       AISA to Max:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">--</div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Adviser Total:
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {/* SUM(advisor fix + advisor %) * (1 - AISA Charge on Advisor)  */}
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //       {numberWithCommas(
                                            //         calculation[1]?.Advisor_Total
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Adviser Fix:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //       {numberWithCommas(
                                            //         calculation[1]?.fixed_charge
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Adviser Percentage:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {getCurrencySymbol(
                                            //         calculation[1]?.currency
                                            //       )}
                                            //       {numberWithCommas(
                                            //         calculation[1]
                                            //           ?.Advisor_Percentage
                                            //       )}
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Split to Advisor:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {/* (Adviser Total (charge on adviser included)) / (Total Fee)  */}
                                            //       {Math.round(
                                            //         calculation[1]
                                            //           ?.Split_To_Advisor * 100
                                            //       )}
                                            //       %
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Split to AISA:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">
                                            //       {/* (1 - Split to Advisor)  */}
                                            //       {Math.round(
                                            //         calculation[1]
                                            //           ?.Split_To_AISA * 100
                                            //       )}
                                            //       %
                                            //     </div>
                                            //   </div>
                                            //   <div className="flex flex-row">
                                            //     <div className="w-3/5">
                                            //       Split to Max:{" "}
                                            //     </div>
                                            //     <div className="w-2/5">--</div>
                                            //   </div>
                                            // </div>
                                            null}
                                      </div>

                                      <button
                                        type="button"
                                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                        onClick={() => setOpen(false)}
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
